<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="12" sm="12" md="10" lg="10" xl="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">Admin Forms</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-btn
              fixed
              fab
              color="primary"
              top
              righvariablet
              style="top: 100px; right: 50px"
              @click="
                localConfig.fields.push({
                  elementType: 'inputField',
                  isRequired: false,
                  conditionalRendering: false,
                  optionalValuesRequired: false,
                  optionalValues: {},
                })
              "
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-card
              elevation="5"
              style="margin-bottom: 25px; padding: 25px"
            >
              <v-row row wrap>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    label="Variable name"
                    v-model="callClassification.variable"
                    :rules="[validateVariableName]"
                    :error-messages="getError"
                    validate-on-blur
                    required
                    :ref="'variable_classification'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    label="Label for 'Successful' status"
                    v-model="callClassification.labels.successfulPlaceHolder"
                    :rules="[validateLabel]"
                    :ref="'label_successfulPlaceHolder'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                  label="Label for 'Unsuccessful' status"
                    v-model="callClassification.labels.unsuccessfulPlaceHolder"
                    :rules="[validateLabel]"
                    :ref="'label_unsuccessfulPlaceHolder'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field
                    label="Label for 'Uncertain' status"
                    v-model="callClassification.labels.uncertainPlaceHolder"
                    :rules="[validateLabel]"
                    :ref="'label_uncertainPlaceHolder'"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" class="pt-0 pb-0">
                  <v-checkbox
                    v-if="!field.conditionalRendering"
                    v-model="field.isRequired"
                    color="primary"
                    label="Is Required?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" v-if="index!=0 && !realtimeFeedback" class="pt-0 pb-0">
                  <v-checkbox
                    v-model="field.conditionalRendering"
                    color="primary"
                    label="Conditional Rendering Required?"
                    @change="updateConditionalRendering(field.conditionalRendering,index)"
                  ></v-checkbox>
                </v-col> -->
              </v-row> 
            </v-card>
            
            <v-card v-if="initializeFormBuilderFlag && field.elementType !== 'callStatus'"
              v-for="(field, index) in localConfig.fields"
              :key="index"
              elevation="5"
              style="margin-bottom: 25px; padding: 25px"
            >
              <FormBuilder
                ref="formBuilder"
                :field="field"
                :index="index"
                :fields="localConfig.fields"
                :parentComponent = "'adminForm'"  
              ></FormBuilder>
            </v-card>
            <div class="text-right">
              <v-btn color="primary" @click="submitForm">UPDATE</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FormBuilder from "@/components/FormBuilder.vue";
import {base_url, end_points} from "@/config";
let axiosInstance = window.axiosInstance;
export default {
  data: () => ({
    fieldTypes: [
      { value: "multiSelectDropdown", text: "Multi-Select dropdown" },
      { value: "dropdown", text: "Dropdown" },
      { value: "inputField", text: "Input Text" },
      { value: "textarea", text: "TextArea" },
    ],
    callClassification: {
      elementType: 'callStatus',
      variable: "",
      labels: {
        unsuccessfulPlaceHolder: "",
        successfulPlaceHolder: "",
        uncertainPlaceHolder: ""
      },     
    },
    localConfig: {},
    currentFlow: "",
    isValid: true,
    initializeFormBuilderFlag: false,
    console,
  }),
  components: {
    FormBuilder: FormBuilder,
  },
  methods: {
    validateAndFocus(ref, value, validator) {
      if (validator(value) !== true) {
        this.$refs[ref].focus();
        this.$refs[ref].blur();
        return false;
      }
      return true;
    },
    async submitForm() {
      if (this.$refs.formBuilder.length < 1) {
        eventBus.$emit(
          "vueSnack",
          "Minimum 1 admin feedback instruction is required! "
        );
        return false;
      } else {
        // For call classification
        if (!this.validateAndFocus('variable_classification', this.callClassification.variable, this.validateVariableName)) {
          return false;
        }

        for (const label in this.callClassification.labels) {
          if (!this.validateAndFocus(`label_${label}`, this.callClassification.labels[label], this.validateLabel)) {
            return false;
          }
        }
        // For feedback form
        for (let i = 0; i < this.$refs.formBuilder.length; i++) {
          
          let labels = Object.keys(this.$refs.formBuilder[i].$refs);
          console.log(labels);
          let gValid = true;
          for (let label of labels) {
            if (label.indexOf("variable") > -1) {
              let valid = this.validateVariableName(
                this.$refs.formBuilder[i].$refs[label].value
              );
              console.log(valid);
              if (valid !== true) {
                this.$refs.formBuilder[i].$refs[label].focus();
                this.$refs.formBuilder[i].$refs[label].blur();
                return false;
              }
            } else if (label.indexOf("label") > -1) {
              let valid = this.validateLabel(
                this.$refs.formBuilder[i].$refs[label].value
              );
              console.log(valid);
              if (valid !== true) {
                this.$refs.formBuilder[i].$refs[label].focus();
                this.$refs.formBuilder[i].$refs[label].blur();
                return false;
              }
            } else if (label.indexOf("options") > -1) {
              if (
                this.$refs.formBuilder[i] &&
                this.$refs.formBuilder[i].$refs[label]
              ) {
                let valid = this.validateOptions(
                  this.$refs.formBuilder[i].$refs[label].value
                );
                if (valid !== true) {
                  this.$refs.formBuilder[i].$refs[label].focus();
                  this.$refs.formBuilder[i].$refs[label].blur();
                  return false;
                }
              }
              if (this.localConfig.fields[i].elementType === 'radio') {
                if (this.localConfig.fields[i]?.options?.length < 2 || this.localConfig.fields[i]?.options?.length > 4) {
                  return false;
                }
              }
              if(!this.localConfig.fields[i]?.optionalValue && this.localConfig.fields[i]?.options){
                // this.$refs.formBuilder[i].$refs[label].focus();
                // this.$refs.formBuilder[i].$refs[label].blur();
                try {
                  const res = await axiosInstance.post(base_url + end_points.csvValidator, { data: this.localConfig.fields[i].options });
                  if(res && res.data && res.data.data) {
                    this.localConfig.fields[i].options = res.data.values;
                    this.localConfig.fields[i].optionsValue = res.data.data;
                  } else {
                    eventBus.$emit("vueSnack", res?.data?.message ?? "Something went wrong while updating drop down fields!");
                  }
                } catch(err){
                  console.error("Something went wrong while updating drop down fields: ", err);
                  eventBus.$emit("vueSnack", "Something went wrong while updating drop down fields!");
                }
              }
            } else if (label.indexOf("conditionalRenderVariable") > -1) {
              if (this.$refs.formBuilder[i].$refs[label]) {
                let valid = this.validateConditionalRender(
                  this.$refs.formBuilder[i].$refs[label].selectedItems
                );
                console.log(valid);
                if (valid !== true) {
                  console.log(this.$refs.formBuilder[i].$refs[label]);
                  this.$refs.formBuilder[i].$refs[label].focus();
                  // For some reasons, was unable to highlight v-select element
                  // this.$refs.formBuilder[i].$refs[label].blur();
                  return false;
                }
              }
            } else if (label.indexOf("conditionalValue") > -1) {
              let value;
              if (
                this.$refs.formBuilder[i].$refs[label] &&
                !this.$refs.formBuilder[i].$refs[label].lazyValue
              ) {
                this.$refs.formBuilder[i].$refs[label].focus();
                this.$refs.formBuilder[i].$refs[label].blur();
                return false;
              }
              this.$nextTick(() => {
                if (this.$refs.formBuilder[i].$refs[label].lazyValue) {
                  value = this.$refs.formBuilder[i].$refs[label].lazyValue;

                } else if (
                  this.$refs.formBuilder[i].$refs[label].initialValue
                ) {
                  value = this.$refs.formBuilder[i].$refs[label].initialValue;
                }
                let valid = this.validateLabel(value);
                console.log(valid);
                if (valid !== true) {
                  this.$refs.formBuilder[i].$refs[label].focus();
                  this.$refs.formBuilder[i].$refs[label].blur();
                  return false;
                }
              });
            } else if (label.startsWith("optionalValue_")) {
              let validArr = [];
              let objKeysForRef = Object.keys(
                this.$refs.formBuilder[i].$refs
              ).filter((key) => key.startsWith("optionalValue_"));
              objKeysForRef.forEach((ele) => {
                if (
                  this.$refs.formBuilder[i].$refs[ele].length > 0 &&
                  /^\s+$/.test(this.$refs.formBuilder[i].$refs[ele][0].value)
                ) {
                  this.$refs.formBuilder[i].$refs[ele][0].focus();
                  this.$refs.formBuilder[i].$refs[ele][0].blur();
                  validArr.push(false);
                } else {
                  validArr.push(true);
                }
              });
              if (validArr.includes(false)) {
                return false;
              }
            }
          }
        }
        for (let i = this.localConfig.fields.length - 1; i >= 0; i--) {
          if (this.localConfig.fields[i].elementType === 'callStatus') {
            this.localConfig.fields.splice(i, 1);
            break;
          }
        }
        
        this.localConfig.fields.push(this.callClassification);
        if(this.currentFlow != "default"){
          this.config.multiflow[this.currentFlow] = JSON.parse(JSON.stringify(this.localConfig));
        } else{
          this.config = JSON.parse(JSON.stringify(this.localConfig))
        }
        eventBus.$emit("updateConfig", ["fields"]);
        eventBus.$on("updatedSuccessfully", (flag)=>{
          this.$nextTick(()=> {
            this.getFlowData();
          })
        });
        return true;
      }
    },
    validateVariableName(val) {
      if (!val || !val.length) {
        return "Variable name can't be left empty";
      }

      if (/^[0-9]/.test(val)) {
        this.isValid = false;
        return "Variable name can't start with a number";
      }
      if (!/^[a-zA-Z0-9_]+$/.test(val)) {
        return "Invalid Characters Present (alphanumeric only)";
      }

      let counter = 0;
      for (let i = 0; i < this.localConfig.fields.length; i++) {
        let v = this.localConfig.fields[i].variable;
        if (v == val) counter++;
        if (counter > 1) {
          this.isValid = false;
          return "This variable already exists";
        }
      }

      this.isValid = true;
      return true;
    },

    validateOptions(val) {
      console.log(val);
      if (!val || !val.length) {
        this.isValid = false;
        return "At least one option is required";
      }

      return true;
    },

    validateLabel(val) {
      if (!val || !val.length) {
        return "Label can't be left empty";
      }

      return true;
    },
    validateConditionalRender(val) {
      console.log(val);
      if (!val || val.length === 0) {
        return "Conditional Render needs a varaible";
      }
      return true;
    },
    getFlowData() {
      if (this.currentFlow != "default") {
        this.localConfig = this.config.multiflow[this.currentFlow];
      } else {
        this.localConfig = this.config;
      }
      
      //handling the data fields missed to initialization from undefined which causing UI breaking
      if(this.localConfig.fields && this.localConfig.fields.length) {
        this.localConfig.fields.forEach((item) => {
          if(item.elementType != 'callStatus') {
            item.elementType = item.elementType || '';
            item.isRequired = item.isRequired || false;
            item.conditionalRendering = item.conditionalRendering || false;
            item.optionalValuesRequired = item.optionalValuesRequired || false;
            item.optionalValues = item.optionalValues || {};
            item.variable = item.variable || '';
            item.placeHolder = item.placeHolder || '';
            item.options = item.options && item.options.length ? item.options : [];
            item.optionsValue = item.optionsValue || '';
            item.conditionalRenderingConditions = item.conditionalRenderingConditions && Object.keys(item.conditionalRenderingConditions).length ? item.conditionalRenderingConditions : {};
          } else {
            this.callClassification.elementType = item.elementType || 'callstatus';
            this.callClassification.variable = item.variable || '';
            this.callClassification.labels = item.labels || {};
          }
        })
      }

      this.initializeFormBuilderFlag = true;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.currentFlow = to.params.id;
    this.getFlowData();
    next();
  },
  beforeMount() {
    this.currentFlow = this.$route.params.id;
    this.getFlowData();
  },
  created() {
    this.initializeFormBuilderFlag = false;
    this.currentFlow = this.$route.params.id;
    this.getFlowData();
  },
  props: ["config"],
};
</script>
